#footer-mobile {
  display: none;
  position: sticky;
  bottom: 0;
  left: 0;
}

#footer-mobile-none {
  display: none;
}

@media screen and (max-width: 760px) {
  #footer-mobile {
    display: block;
    /* position: absolute;
    bottom: 0; */
  }
}
