.wrapper {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 120px;
  height: 180px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  flex-direction: column;
  z-index: 1000;
}

.coccoc-alo-phone {
  background-color: transparent;
  width: 70px;
  height: 70px;
  cursor: pointer;
  z-index: 200000 !important;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  -webkit-transition: visibility 0.5s;
  -moz-transition: visibility 0.5s;
  -o-transition: visibility 0.5s;
  transition: visibility 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-circle-fill {
  background-color: #c8e6c9;
  opacity: 0.75 !important;
}

.coccoc-alo-ph-circle-fill {
  width: 70px;
  height: 70px;
  top: 40px;
  left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a4a49;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 0.1;
  -webkit-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.coccoc-alo-ph-img-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 0.7;
  -webkit-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-img-circle {
  background-color: #080808;
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-circle {
  border-color: #0a4a49;
  opacity: 0.5;
}

.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover .coccoc-alo-ph-circle,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-circle {
  border-color: #b2dfdb;
  opacity: 0.5;
}

.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover .coccoc-alo-ph-circle-fill,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-circle-fill {
  background-color: #ff9800;
  opacity: 0.75 !important;
}

.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover .coccoc-alo-ph-img-circle,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-img-circle {
  background-color: #ff9800;
}

/* icon messenger  ----------------------------------------------------------*/
.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover
  .coccoc-alo-ph-circle-fill-mess,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-circle-fill-mess {
  background-color: #ff9800;
  opacity: 0.75 !important;
}

.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover
  .coccoc-alo-ph-img-circle-mess,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-img-circle-mess {
  background-color: #ff9800;
}

.coccoc-alo-ph-circle-fill-mess {
  width: 70px;
  height: 70px;
  top: 40px;
  left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a4a49;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 0.1;
  -webkit-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.coccoc-alo-ph-img-circle-mess {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  border: 2px solid transparent;
  opacity: 0.7;
  -webkit-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -moz-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -ms-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  -o-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
  animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-circle-fill-mess {
  background-color: #c8e6c9;
  opacity: 0.75 !important;
}

/* ---------------------------------------------------------- */

@-moz-keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }

  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }

  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-webkit-keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }

  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }

  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-o-keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }

  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }

  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@keyframes coccoc-alo-circle-anim {
  0% {
    transform: rotate(0) scale(0.5) skew(1deg);
    opacity: 0.1;
  }

  30% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.5;
  }

  100% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.1;
  }
}

@-moz-keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }

  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }

  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-webkit-keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }

  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }

  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-o-keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }

  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }

  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@keyframes coccoc-alo-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }

  50% {
    transform: rotate(0) scale(1) skew(1deg);
    opacity: 0.2;
  }

  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
    opacity: 0.2;
  }
}

@-moz-keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@-webkit-keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@-o-keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@keyframes coccoc-alo-circle-img-anim {
  0% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  10% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  20% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  30% {
    transform: rotate(-25deg) scale(1) skew(1deg);
  }

  40% {
    transform: rotate(25deg) scale(1) skew(1deg);
  }

  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  100% {
    transform: rotate(0) scale(1) skew(1deg);
  }
}

@media screen and (max-width: 760px) {
  .wrapper {
    display: none;
  }
}
