.selected {
  border: 1px solid #4caf50;
}
.unselected {
  opacity: 0.5;
}

.description img {
  width: 50%;
  height: 50%;
  display: block;
  margin: 0 auto;
}
