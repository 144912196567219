*,
*::before,
*::after {
    box-sizing: border-box;
    scroll-behavior: smooth;
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
}

.App {
    background-image: url('/public/image/bg.png');
}